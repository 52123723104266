define("happyfox-bi/data-sources/index/controller", ["exports", "ember-light-table", "happyfox-bi/mixins/table"], function (_exports, _emberLightTable, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_table.default, {
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    isOnDemandSyncEnabled: Ember.computed.reads('featuresService.onDemandSync'),
    tableProperties: Ember.computed('', function () {
      return Ember.Object.create({
        onSyncInitiated: this.actions.onSyncInitiated.bind(this)
      });
    }),
    initiate: function initiate() {
      var dataSources = this.get('model');
      var columns = [{
        valuePath: 'name',
        label: 'Name',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column'
      }, {
        valuePath: 'sourceType',
        label: 'Source Type',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column',
        cellComponent: 'data-sources/components/table-cell/source-type'
      }, {
        valuePath: 'lastSyncAt',
        label: 'Last Sync At',
        sortable: true,
        resizable: true,
        minResizeWidth: 100,
        component: 'data-table/column'
      }];

      if (this.get('isOnDemandSyncEnabled')) {
        var syncColumn = {
          valuePath: 'id',
          label: 'Sync',
          sortable: false,
          width: '70px',
          cellComponent: 'data-sources/components/table-cell/on-demand-sync'
        };
        columns.push(syncColumn);
      }

      this.set('columns', columns);

      var table = _emberLightTable.default.create({
        columns: columns,
        rows: dataSources
      });

      var rows = table.rows;
      this.setProperties({
        rows: rows,
        table: table
      });
    },
    actions: {
      onSyncInitiated: function onSyncInitiated() {
        this.send('refresh');
      },
      onDataSourcesUpdate: function onDataSourcesUpdate(rows) {
        this.get('table').setRows(rows);
        this.set('rows', this.get('table.rows'));
      }
    }
  });

  _exports.default = _default;
});