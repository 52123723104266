define("happyfox-bi/components/auto-complete/local-and-remote/multiple/component", ["exports", "happyfox-bi/constants", "happyfox-bi/mixins/register-waiter", "happyfox-bi/utilities/monitor-async-action", "happyfox-bi/utilities/object"], function (_exports, _constants, _registerWaiter, _monitorAsyncAction, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_registerWaiter.default, {
    dataService: Ember.inject.service('data'),
    intlService: Ember.inject.service('intl'),
    isInputDisabled: false,
    selected: null,
    url: null,
    searchParam: null,
    searchField: null,
    placeholder: null,
    options: null,
    optionValuePath: null,
    optionLabelPath: null,
    autofocus: false,
    hasErrors: false,
    isAddNew: true,
    characterLimit: null,
    noMatchesMessage: '',
    itemsToBeIgnored: null,
    isAddSearchHelpOption: false,
    searchText: null,
    canCreateCustomChoiceOnComma: true,
    // can be false if search results/options are expected to have comma
    init: function init() {
      this._super.apply(this, arguments);

      var options = this.get('options');

      if (!options) {
        this.set('options', []);
      }
    },
    itemsToBeExcluded: Ember.computed('selected.[]', {
      get: function get() {
        var selectedItems = Ember.isPresent(this.get('selected')) ? this.get('selected') : Ember.A();
        var itemsToBeIgnored = Ember.isPresent(this.get('itemsToBeIgnored')) ? this.get('itemsToBeIgnored') : Ember.A();
        return selectedItems.mapBy('id').addObjects(itemsToBeIgnored).map(function (item) {
          return Number(item);
        });
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (Ember.isBlank(this.get('selected'))) {
        this.set('selected', Ember.A());
      }

      if (this.get('isInputDisabled')) {
        this.$('.ember-power-select-trigger-multiple-input').prop('disabled', true);
        this.$().addClass('remove-only');
      } else if (this.get('autofocus')) {
        Ember.run.next(function () {
          _this.$('input').focus();
        });
      }
    },
    createCustomChoice: function createCustomChoice(select) {
      var _this2 = this;

      if (Ember.isPresent(select.searchText)) {
        var searchTextArray;

        if (this.get('canCreateCustomChoiceOnComma')) {
          searchTextArray = select.searchText.split(',');
        } else {
          searchTextArray = select.searchText.split();
        }

        searchTextArray = searchTextArray.filter(function (searchText) {
          return !Ember.isEmpty(searchText.trim());
        });
        searchTextArray.forEach(function (searchText, index) {
          var selectedArray = _this2.get('selected');

          if (!selectedArray.includes(searchText)) {
            if (index < searchTextArray.length - 1) {
              _this2.get('selected').pushObject(searchText);
            } else {
              // clearing the power-select input value which doesn't happen by default if we don't use select.actions.choose to set selected
              select.actions.choose(searchText);
            }
          } else {
            Ember.run.later(function () {
              _this2.$('input').val('');
            }, 100);
          }
        });
      }
    },
    addSearchHelpOption: function addSearchHelpOption(results, term) {
      var optionValuePath = this.get('optionValuePath');

      if (Ember.isPresent(results)) {
        var firstResult = Ember.isPresent(optionValuePath) ? results.get('firstObject')[optionValuePath] : results.get('firstObject');

        if (firstResult !== term) {
          results.unshiftObject(Ember.Object.create({
            isSearchHelpOption: true
          }));
          this.set('searchText', term);
        }
      }
    },
    _performSearch: function _performSearch(term, resolve, reject, url, searchParam) {
      var _this3 = this;

      if (Ember.isBlank(term)) {
        return resolve([]);
      }

      var optionValuePath = this.get('optionValuePath');
      var isSearchAction = this.searchAction;
      var searchPromise = isSearchAction ? this.searchAction(term) : this.get('dataService').autoCompleteSearch(searchParam, term, url);
      searchPromise.then(function (results) {
        if (Ember.isPresent(_this3.get('itemsToBeExcluded'))) {
          results = results.reject(function (result) {
            var itemsToBeExcluded = _this3.get('itemsToBeExcluded');

            return itemsToBeExcluded.includes(result.id);
          });
        }

        if (!Ember.isBlank(optionValuePath)) {
          results = results.map(_object.camelizeObject).mapBy(optionValuePath).reject(function (result) {
            return result === null;
          });
        } else {
          results = results.map(_object.camelizeObject);
        }

        if (_this3.get('isAddSearchHelpOption')) {
          _this3.addSearchHelpOption(results, term);
        }

        resolve(results);

        _this3.set('isLoading', false);
      }, reject);
    },
    actions: {
      search: function search(term) {
        var _this4 = this;

        var intlService = this.get('intlService');
        var noMatchesMessage;

        if (term.length >= _constants.AUTOCOMPLETE_MIN_CHARACTER_LENGTH) {
          this.set('isLoading', true); // wait for min two character to be typed by the user before sending request

          var url = this.get('url');
          var searchParam = this.get('searchParam');

          if (this.get('isAddNew')) {
            noMatchesMessage = 'no-matches-message';
          } else {
            noMatchesMessage = 'no-results-found';
          }

          this.set('noMatchesMessage', noMatchesMessage);
          var promise = new Ember.RSVP.Promise(function (resolve, reject) {
            Ember.run.debounce(_this4, _this4._performSearch, term, resolve, reject, url, searchParam, 600);
          });

          if (Ember.testing) {
            (0, _monitorAsyncAction.default)(this, promise);
          }

          return promise;
        } else {
          noMatchesMessage = intlService.lookup('start-search-help-text');
          this.set('noMatchesMessage', noMatchesMessage);
        }
      },
      onChange: function onChange(result, select) {
        if (result.get('lastObject.isSearchHelpOption') || this.get('isLoading')) {
          result.popObject();
          result.addObject(select.searchText);
        }

        if (this.onChange) {
          this.onChange(result);
        }

        if (this.transformResult) {
          result = this.transformResult(result);
        }

        this.set('selected', result);
        select.actions.search();
        this.$('input').val('');
      },
      onKeyDown: function onKeyDown(select, e) {
        var commaEvent = e.keyCode === 188;
        var useCommaToCreateCustomChoice = this.get('canCreateCustomChoiceOnComma');

        if (this.get('isAddNew') && select.isOpen && !Ember.isBlank(select.searchText)) {
          if (commaEvent) {
            if (!useCommaToCreateCustomChoice) {
              return;
            }

            e.preventDefault();
            e.stopPropagation();
            this.createCustomChoice(select);
          } else if (e.keyCode === 13 && !select.highlighted) {
            // if no highlighted options present, create custom option 
            // else create highlighted as selected option (handled in initializer)
            this.createCustomChoice(select);
          }
        } else if (useCommaToCreateCustomChoice && commaEvent && !select.isOpen || useCommaToCreateCustomChoice && commaEvent && !Ember.isBlank(select.searchText.split(',')) || Ember.isPresent(this.get('characterLimit')) && select.searchText.length >= this.get('characterLimit') && !(e.keyCode === 8 || e.keyCode === 46)) {
          // 8 for backspace and 46 for delete. Should allow backspace and delete when text length is greater than or equal to character limit
          e.preventDefault();
        }
      },
      onClose: function onClose(select) {
        if (this.get('isAddNew') && !select.highlighted) {
          // Allowing creation of custom choices only when isAddNew is true and there are no suggestions for the typed text
          this.createCustomChoice(select);
        }
      },
      onOpen: function onOpen() {
        if (this.onOpen) {
          this.onOpen.apply(this, arguments);
        }
      }
    }
  });

  _exports.default = _default;
});