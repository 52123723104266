define("happyfox-bi/data-sources/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dataService: Ember.inject.service('data'),
    featuresService: Ember.inject.service('features'),
    dataSources: null,
    showCreateDataSource: false,
    canManageDataSources: Ember.computed.reads('featuresService.manageDataSources'),
    dataSourcesIndexController: Ember.inject.controller('data-sources.index'),
    actions: {
      toggleCreateDataSource: function toggleCreateDataSource() {
        this.toggleProperty('showCreateDataSource');
      },
      reloadDataSources: function reloadDataSources() {
        var dataSources = this.get('dataService').peekAllDataSources();
        this.set('dataSources', dataSources);
        this.get('dataSourcesIndexController').send('onDataSourcesUpdate', dataSources);
      },
      goToDataSources: function goToDataSources() {
        this.transitionToRoute('data-sources');
      },
      goToDataSets: function goToDataSets(dataSourceId) {
        this.transitionToRoute('data-sources.details.data-sets', dataSourceId);
      }
    }
  });

  _exports.default = _default;
});