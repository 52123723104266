define("happyfox-bi/components/widget-filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['widget-filters'],
    fields: null,
    filters: null,
    prefetchedFiltersValueOptions: null,
    visualizationId: null,
    fieldSelectionComponent: false,
    unAppliedFilterFields: Ember.computed('fields.@each.{name,isEditable}', 'filters.@each.field', {
      get: function get() {
        var editableFields = this.get('fields');
        var appliedFilterFieldNames = this.get('filters').mapBy('field');
        var fields = editableFields.filter(function (field) {
          var fieldName = field.get('name');
          return !appliedFilterFieldNames.includes(fieldName);
        });
        return fields;
      }
    }),
    cloneFilters: function cloneFilters() {
      return JSON.parse(JSON.stringify(this.get('filters')));
    },
    actions: {
      updateFilter: function updateFilter(data) {
        var filters = this.cloneFilters();
        var filter = filters.findBy('field', data.get('field'));
        filter.predicate = data.get('predicate');
        filter.value = data.get('value');
        this.applyFilters(filters);
      },
      removeFilter: function removeFilter(field) {
        var filters = this.cloneFilters();
        var filter = filters.findBy('field', field);
        filters.removeObject(filter);
        this.applyFilters(filters);
      },
      addFilter: function addFilter(data) {
        var filters = this.cloneFilters();
        filters.push(data);
        this.applyFilters(filters);
      },
      addSelectedField: function addSelectedField(field) {
        this.get('fields').addObject(field);
      },
      fetchDataSetFields: function fetchDataSetFields() {
        return this.fetchDataSetFields();
      },
      onFormFieldChange: function onFormFieldChange() {
        var _this$onFormFieldChan;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        (_this$onFormFieldChan = this.onFormFieldChange) === null || _this$onFormFieldChan === void 0 ? void 0 : _this$onFormFieldChan.call.apply(_this$onFormFieldChan, [this].concat(args));
      },
      onPopOverOpen: function onPopOverOpen() {
        var _this$onPopOverOpen;

        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        (_this$onPopOverOpen = this.onPopOverOpen) === null || _this$onPopOverOpen === void 0 ? void 0 : _this$onPopOverOpen.call.apply(_this$onPopOverOpen, [this].concat(args));
      }
    }
  });

  _exports.default = _default;
});