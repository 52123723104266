define("happyfox-bi/components/value-filter-field-selection/component", ["exports", "happyfox-bi/visualizations/new/utilities/field-name"], function (_exports, _fieldName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataSetField: null,
    dataSetFieldOptions: null,
    loadingOptions: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('loadingOptions', true);
      this.fetchDataSetFields().then(function (_ref) {
        var datasetFields = _ref.datasetFields;
        var datasetFieldsWithoutDataSets = datasetFields.filter(function (field) {
          return field.dataType !== 'dataset';
        });

        _this.set('dataSetFieldOptions', datasetFieldsWithoutDataSets);
      }).finally(function () {
        _this.set('loadingOptions', false);
      });
    },
    actions: {
      scrollPowerSelectDropdownIntoView: function scrollPowerSelectDropdownIntoView(className) {
        var _this2 = this;

        Ember.run.later(function () {
          var selectDropdown = _this2.element.querySelector(".".concat(className));

          if (selectDropdown) {
            selectDropdown.scrollIntoView({
              behavior: "smooth"
            });
          }
        }, 150);
      },
      onDataSetFieldChange: function onDataSetFieldChange(field) {
        this.set('dataSetField', field);
        var data = Ember.Object.create({
          'name': (0, _fieldName.getUniqueFieldName)(field.name),
          'displayName': field.displayName,
          'type': 'dataset_field',
          'dataset': field.dataset,
          'dataType': field.dataType,
          'datasetField': field.id,
          'isEditable': false
        });

        if (field.parentDataset) {
          data.parentDataset = field.parentDataset;
          data.parentDatasetField = field.parentDatasetField;
        }

        this.onFieldSelection(data);
      }
    }
  });

  _exports.default = _default;
});